.Impressum{
    width: 100%;
}

.Datenschutz{
    width: 100%;
}

.imp-dat-inner{
    margin: 0 auto;
    width: 100%;
    padding: 56px 16px;
    box-sizing: border-box;
    max-width: 1000px;
}

.imp-dat-big-h{
    text-align: left;
    font-size: 2.56em;
    font-weight: bold;
    margin-bottom: 36px;
}

.imp-dat-sub-h{
    text-align: left;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 16px;
}

.imp-dat-text{
    margin: 0 0 56px 0;
    text-align: left;
    font-size: 1em;
}