.HeadlineCombo{
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 100px 30px 0 30px;
    box-sizing: border-box;
}

.headline-combo-subheadline{
    font-size: 1.24em;
    color: #c60204;
    margin: 0;
}

.headline-combo-headline{
    font-size: 3.16em;
    margin: 10px 0 0 0;
}