@media screen and (min-width: 1024px) {
  .HalfHalf {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .half-half-half {
    width: 46%;
    margin: 2%;
    padding: 20px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .half-half-half-img {
    width: 46%;
    margin: 2%;
    padding-bottom: 46%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .half-half-float-left{
    float: left;
  }

  .half-half-float-right{
    float: right;
  }

  .half-half-headline {
    width: 100%;
    text-align: left;
    font-size: 2.8em;
    font-weight: bold;
    margin: 136px 0 0 0;
    color: #c60204;
  }

  .half-half-subheadline {
    width: 100%;
    text-align: left;
    font-size: 1.44em;
    margin: 0;
  }

  .half-half-paragraph {
    width: 100%;
    text-align: left;
    margin: 24px 0 0 0;
    font-size: 1.12em;
  }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
  .HalfHalf {
    width: 100%;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .half-half-half {
    width: 46%;
    margin: 2%;
    padding: 20px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .half-half-half-img {
    width: 46%;
    margin: 2%;
    padding-bottom: 46%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .half-half-float-left{
    float: left;
  }

  .half-half-float-right{
    float: right;
  }

  .half-half-headline {
    width: 100%;
    text-align: left;
    font-size: 2.2em;
    font-weight: bold;
    margin: 72px 0 0 0;
    color: #c60204;
  }

  .half-half-paragraph {
    width: 100%;
    text-align: left;
    margin: 24px 0 0 0;
    font-size: 1.1em;
  }
}



@media screen and (max-width: 767px) {
  .HalfHalf {
    width: 100%;
    margin: 0 auto;
    margin: 36px 0px;
  }

  .half-half-half {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .half-half-half-img {
    width: 61%;
    padding-bottom: 61%;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .half-half-headline {
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin: 4px 0 0 0;
    color: #c60204;
  }

  .half-half-paragraph {
    width: 100%;
    text-align: center;
    margin: 24px 0 0 0;
    font-size: 1em;
  }
}
