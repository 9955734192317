@media screen and (min-width: 1024px) {
    .Footer{
        padding: 60px 30px;
        box-sizing: border-box;
        width: 100%;
        height: 230px;
        background-color: #EEE;
    }
    
    .footer-left-logo{
        float: left;
    }
    
    .footer-logo{
        float: left;
        height: 64px;
        margin: 20px 0 6px 0;
    }
    
    .footer-legal-paragraph{
        clear: left;
        text-align: left;
        color: #AAA;
        font-size: 0.72em;
    }
    
    .footer-right{
        float: right;
    }
    
    .footer-menue-points{
        float: left;
    }
    
    .footer-menue-row{
        margin: 16px 0 0 16px;
        float: right;
        padding: 0 6px;
    }
    
    .footer-menue-element{
        clear: left;
        width: 100px;
        text-align: left;
        float: left;
        margin: 10px 0 0 10px;
        font-weight: bold;
    }
    
    .footer-adress{
        margin-left: 24px;
        float: right;
    }
    
    .footer-call-button{
        float: left;
        width: 100px;
        padding: 10px 20px;
        color: white;
        background-color: #c60204;
        border-radius: 6px;
        margin-bottom: 6px;
    }
    
    .footer-adress-paragraph{
        text-align: left;
        font-size: 0.8em;
    }
  }
  
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .Footer{
        padding: 60px 30px;
        box-sizing: border-box;
        width: 100%;
        background-color: #EEE;
    }
    
    .footer-left-logo{
       width: 100%;
    }
    
    .footer-logo{
        float: left;
        height: 64px;
        margin-bottom: 10px;
    }
    
    .footer-legal-paragraph{
        clear: left;
        text-align: left;
        color: #AAA;
        font-size: 0.72em;
    }
    
    .footer-right{
        width: 100%;
    }
    
    .footer-menue-points{
        width: 100%;
    }
    
    .footer-menue-row{
        width: 100%;
    }
    
    .footer-menue-element{
        width: 100px;
        text-align: left;
        margin: 16px 0 0 0;
        font-weight: bold;
    }
    
    .footer-adress{
        margin-left: 24px;
    }
    
    .footer-call-button{
        float: left;
        width: 100px;
        padding: 10px 20px;
        color: white;
        background-color: #c60204;
        border-radius: 6px;
        margin: 6px 0 6px 0;
    }
    
    .footer-adress-paragraph{
        width: 100%;
        clear: both;
        text-align: left;
        font-size: 0.8em;
        margin: 16px 0 32px 0;
    }
  }
  
  
  @media screen and (max-width: 767px) {
    .Footer{
        padding: 60px 30px;
        box-sizing: border-box;
        width: 100%;
        background-color: #EEE;
    }
    
    .footer-left-logo{
       width: 100%;
    }
    
    .footer-logo{
        float: left;
        height: 64px;
        margin-bottom: 10px;
    }
    
    .footer-legal-paragraph{
        clear: left;
        text-align: left;
        color: #AAA;
        font-size: 0.64em;
    }
    
    .footer-right{
        width: 100%;
    }
    
    .footer-menue-points{
        width: 100%;
    }
    
    .footer-menue-row{
        width: 100%;
    }
    
    .footer-menue-element{
        width: 100px;
        text-align: left;
        margin: 16px 0 0 0;
        font-weight: bold;
    }
    
    .footer-adress{
        margin-left: 24px;
    }
    
    .footer-call-button{
        float: left;
        width: 100px;
        padding: 10px 20px;
        color: white;
        background-color: #c60204;
        border-radius: 6px;
        margin: 6px 0 6px 0;
    }
    
    .footer-adress-paragraph{
        width: 100%;
        clear: both;
        text-align: left;
        font-size: 0.8em;
        margin: 16px 0 32px 0;
    }
  }



