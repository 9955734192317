@font-face {
  font-family: "Lato";
  font-weight: light;
  src: url("./assets/font/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato";
  font-weight: bold;
  src: url("./assets/font/Lato-Bold.ttf");
}


html, body, #root{
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
}

a{
  text-decoration: none;
  color: black;
}


input{
  -webkit-appearance:none;
  border-radius: 0;
}