.FourZeroFour {
  width: 100%;
  height: 100%;
}

.fourzerofour-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fourzerofour-image {
  width: 100%;
}

.fourzerofour-paragraph {
  margin: -32px 0 0 0;
  font-size: 1.2em;
}
