.VerticalSpacer{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: -999;
}

.verticalspacer-inner{
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: #EDEDED;
}