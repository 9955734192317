@media screen and (min-width: 1024px) {
    .ContactBar{
        padding: 6px 30px;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
        background-color: #c60204;
    }

    .contactbar-text-element{
        float: right;
        color: white;
        margin: -2px 0 0 16px;
    }

    .contactbar-icon{
        margin: 4px 4px 0 0;
        height: 15px;
    }
    
  }
  
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .ContactBar{
        padding: 6px 30px;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
        background-color: #c60204;
    }

    .contactbar-text{
        text-align: right;
        color: white;
        margin: -2px 0 0 0;
    }

    .contactbar-text-element{
        float: right;
        color: white;
        margin: -2px 0 0 16px;
    }

    .contactbar-icon{
        margin: 4px 4px 0 0;
        height: 15px;
    }
  }
  
  
  @media screen and (max-width: 767px) {
    .ContactBar{
        padding: 6px 16px 10px 6px;
        box-sizing: border-box;
        width: 100%;
        background-color: #c60204;
    }

    .contactbar-text-element{
        width: 100%;
        text-align: center;
        color: white;
        margin: -2px 0 0 0;
        font-size: 0.82em;
    }

    .contactbar-icon{
        margin: 4px 4px 0 0;
        height: 14px;
    }
  }



