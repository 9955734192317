@media screen and (min-width: 1024px) {
  .TextBox {
    margin: -200px 60px 0 60px;
    background-color: #c60204;
    padding: 10px 30px;
    box-sizing: border-box;
  }

  .textbox-paragraph {
    color: white;
    font-size: 1.2em;
  }

  .big-span {
    font-size: 1.44em;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .TextBox {
    margin: -200px 60px 0 60px;
    background-color: #c60204;
    padding: 10px 30px;
    box-sizing: border-box;
  }

  .textbox-paragraph {
    color: white;
    font-size: 1.2em;
  }

  .big-span {
    font-size: 1.44em;
  }
}

@media screen and (max-width: 767px) {
  .TextBox {
    margin: -200px 32px 0 32px;
    background-color: #c60204;
    padding: 10px 16px;
    box-sizing: border-box;
  }

  .textbox-paragraph {
    color: white;
    font-size: 1em;
  }

  .big-span {
    font-size: 1.2em;
  }
}
