@media screen and (min-width: 768px) {
  .Header {
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    position: relative;
  }

  .header-active{
    color: #c60204;
  }

  .header-logo {
    float: left;
    height: 100%;
  }

  .header-button {
    float: right;
    padding: 10px 30px 10px 26px;
    background-color: #c60204;
    margin-left: 36px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
  }

  .header-btn-icon{
    margin: 0 6px 0 0;
    height: 16px;
  }


  .header-button:hover {
    cursor: pointer;
  }

  .header-element {
    float: right;
    padding: 0 0 0 32px;
    font-size: 1.2em;
    margin: 10px 0 0 0;
  }

  .header-element:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .Header {
    padding: 30px 16px 30px 16px;
    box-sizing: border-box;
    width: 100%;
    height: 96px;
    position: relative;
  }

  .header-logo {
    float: left;
    height: 100%;
  }

  .header-hamburger-menue {
    float: right;
    height: 100%;
  }

  .header-hamburger-menue:hover {
    cursor: pointer;
  }

  .header-mobile-content {
    width: 100%;
    position: absolute;
    top: 90px;
    left: 0;
    background-color: white;
    z-index: 999;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
    padding: 20px;
    box-sizing: border-box;
  }

  .header-mobile-active {
    color: #c60204;
  }

  .header-mobile-element {
    text-align: left;
    border-bottom: solid 1px rgb(0, 0, 0, 0.02);
    padding: 3px 0;
    font-size: 1.4em;
  }

  .header-mobile-bottom-row-call {
    width: 100%;
    background-color: #c60204;
    color: white;
    font-size: 1.4em;
    padding: 12px 0;
    border-radius: 6px;
    margin-top: 36px;
    font-weight: bold;
  }

  .header-btn-icon{
    margin: 0 8px 0 0;
    height: 20px;
  }

}
