@media screen and (min-width: 1024px) {
  .ModernHalfHalf {
    position: relative;
    width: 100%;
    height: 600px;
  }

  .modernhalfhalf-absolute-left {
    position: absolute;
    left: 0;
    top: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .modernhalfhalf-absolute-right {
    position: absolute;
    top: 156px;
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .modernhalfhalf-text-box {
    width: 61%;
    height: 100%;
    background-color: #cb0507;
  }

  .modernhalfhalf-img-description {
    position: absolute;
    bottom: 40px;
    left: -256px;
    font-size: 1.4em;
    border-bottom: solid 5px #cb0507;
    text-align: left;
    width: 61%;
  }

  .modernhalfhalf-image-box {
    width: 50%;
    height: 100%;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .modernhalfhalf-right-headline {
    position: relative;
    font-weight: bold;
    font-size: 3em;
  }

  .modernhalfhalf-inner-left {
    position: relative;
    float: left;
    width: 50%;
  }

  .modernhalfhalf-inner-right {
    position: relative;
    float: right;
  }

  .modernhalfhalf-inner-right {
    width: 50%;
    padding: 16px 0 0 12vw;
    box-sizing: border-box;
    text-align: left;
    z-index: 999;
  }

  .modernhalfhalf-inner-left {
    text-align: left;
    z-index: 999;
    padding: 72px 72px 0 0;
    box-sizing: border-box;
  }

  .modernhalfhalf-inner-left-headline {
    font-size: 2em;
    color: white;
    font-weight: bold;
    font-size: 3em;
  }

  .modernhalfhalf-inner-left-text {
    color: white;
    text-align: left;
    font-size: 1.2em;
    margin-top: 24px;
  }

  .modernhalfhalf-centered-content {
    margin: 0 auto;
    width: 1024px;
    padding: 1px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ModernHalfHalf {
    position: relative;
    width: 100%;
    height: 600px;
  }

  .modernhalfhalf-absolute-left {
    position: absolute;
    left: 0;
    top: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .modernhalfhalf-absolute-right {
    position: absolute;
    top: 156px;
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .modernhalfhalf-text-box {
    width: 61%;
    height: 100%;
    background-color: #cb0507;
  }

  .modernhalfhalf-img-description {
    position: absolute;
    bottom: 20px;
    left: -55%;
    font-size: 1.4em;
    border-bottom: solid 5px #cb0507;
    text-align: left;
    width: 61%;
  }

  .modernhalfhalf-image-box {
    width: 50%;
    height: 100%;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .modernhalfhalf-right-headline {
    position: relative;
    font-weight: bold;
    font-size: 3em;
  }


  .modernhalfhalf-inner-left {
    position: relative;
    float: left;
    width: 50%;
  }

  .modernhalfhalf-inner-right {
    position: relative;
    float: right;
  }

  .modernhalfhalf-inner-right {
    width: 50%;
    padding: 16px 0 0 12vw;
    box-sizing: border-box;
    text-align: left;
    z-index: 999;
  }

  .modernhalfhalf-inner-left {
    text-align: left;
    z-index: 999;
    padding: 72px 72px 0 0;
    box-sizing: border-box;
  }

  .modernhalfhalf-centered-content {
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .modernhalfhalf-inner-left-headline {
    font-size: 2em;
    color: white;
    font-weight: bold;
    font-size: 3em;
  }

  .modernhalfhalf-inner-left-text {
    color: white;
    text-align: left;
    font-size: 1.2em;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .ModernHalfHalf {
    position: relative;
    width: 100%;
    height: 600px;
  }

  .modernhalfhalf-absolute-left {
    position: absolute;
    left: 0;
    top: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .modernhalfhalf-absolute-right {
    position: absolute;
    top: 400px;
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .modernhalfhalf-text-box {
    width: 94%;
    height: 100%;
    background-color: #cb0507;
  }

  .modernhalfhalf-img-description {
    position: absolute;
    bottom: -100px;
    font-size: 1em;
    border-bottom: solid 5px #cb0507;
    border-right: solid 5px #cb0507;
    padding-top: 40px;
    text-align: left;
    width: 61%;
  }

  .modernhalfhalf-image-box {
    width: 94%;
    height: 350px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .modernhalfhalf-right-headline {
    position: relative;
    font-weight: bold;
    font-size: 1em;
    display: none;
  }


  .modernhalfhalf-inner-left {
    position: relative;
    float: left;
    width: 90%;
  }

  .modernhalfhalf-inner-right {
    position: relative;
    float: right;
  }

  .modernhalfhalf-inner-right {
    width: 50%;
    padding: 16px 0 0 12vw;
    box-sizing: border-box;
    text-align: left;
    z-index: 999;
  }

  .modernhalfhalf-inner-left {
    text-align: left;
    z-index: 999;
    padding: 30px 0;
    box-sizing: border-box;
  }

  .modernhalfhalf-centered-content {
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .modernhalfhalf-inner-left-headline {
    font-size: 2em;
    color: white;
    font-weight: bold;
  }

  .modernhalfhalf-inner-left-text {
    color: white;
    text-align: left;
    font-size: 1.1em;
    margin-top: 24px;
    background-color: #cb0507;
  }
}
