@media screen and (min-width: 1024px) {
    
.Downloads {
    width: 100%;
    height: 100%;
  }
  
  .downloads-inner {
    width: 100%;
    min-height: 100% !important;
    padding: 0 30px;
    box-sizing: border-box;
  }
  
  .small-hero {
    width: 100%;
    padding: 92px 30px;
    box-sizing: border-box;
    background-color: #c60204;
  }
  
  .small-hero-h {
    text-align: left;
    color: white;
    font-size: 5em;
    font-weight: bold;
    margin: 0;
  }
  
  .small-hero-sub-h {
    margin: 0;
    text-align: left;
    color: white;
    font-size: 1.2em;
  }
  
  .downloads-centered {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
    padding: 30px;
    box-sizing: border-box;
  }
  
  .downloads-row-element {
    padding: 30px 30px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 6px 12px #eee;
    margin-bottom: 36px;
    height: 100px;
  }
  
  .downloads-row-element:hover {
    cursor: pointer;
  }
  
  .downloads-row-element-img {
    height: 100%;
    float: left;
  }
  
  .downloads-row-element-open-img {
    height: 36px;
    margin-top: 32px;
    float: right;
  }
  
  .downloads-row-element-text {
    font-size: 1.72em;
    margin: 44px 24px;
    float: left;
  }
  
  }
  
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
       
.Downloads {
    width: 100%;
    height: 100%;
  }
  
  .downloads-inner {
    width: 100%;
    min-height: 100% !important;
    padding: 0 30px;
    box-sizing: border-box;
  }
  
  .small-hero {
    width: 100%;
    padding: 92px 30px;
    box-sizing: border-box;
    background-color: #c60204;
  }
  
  .small-hero-h {
    text-align: left;
    color: white;
    font-size: 5em;
    font-weight: bold;
    margin: 0;
  }
  
  .small-hero-sub-h {
    margin: 0;
    text-align: left;
    color: white;
    font-size: 1.2em;
  }
  
  .downloads-centered {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
    padding: 30px;
    box-sizing: border-box;
  }
  
  .downloads-row-element {
    padding: 30px 30px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 6px 12px #eee;
    margin-bottom: 36px;
    height: 100px;
  }
  
  .downloads-row-element:hover {
    cursor: pointer;
  }
  
  .downloads-row-element-img {
    height: 100%;
    float: left;
  }
  
  .downloads-row-element-open-img {
    height: 36px;
    margin-top: 32px;
    float: right;
  }
  
  .downloads-row-element-text {
    font-size: 1.72em;
    margin: 44px 24px;
    float: left;
  }
  }
  
  
  @media screen and (max-width: 767px) {
        
.Downloads {
    width: 100%;
    height: 100%;
  }
  
  .downloads-inner {
    width: 100%;
    min-height: 100% !important;
    padding: 0 16px;
    box-sizing: border-box;
  }
  
  .small-hero {
    width: 100%;
    padding: 92px 16px;
    box-sizing: border-box;
    background-color: #c60204;
  }
  
  .small-hero-h {
    text-align: left;
    color: white;
    font-size: 3.44em;
    font-weight: bold;
    margin: 0;
  }
  
  .small-hero-sub-h {
    margin: 0;
    text-align: left;
    color: white;
    font-size: 1.2em;
  }
  
  .downloads-centered {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
    padding: 16px;
    box-sizing: border-box;
  }
  
  .downloads-row-element {
    padding: 30px 30px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 6px 12px #eee;
    margin-bottom: 36px;
  }
  
  .downloads-row-element:hover {
    cursor: pointer;
  }
  
  .downloads-row-element-img {
    height: 100px;
    
  }
  
  .downloads-row-element-open-img {
    display: none;
  }
  
  .downloads-row-element-text {
    font-size: 1.56em;
    word-spacing:9999px;
  }
  }