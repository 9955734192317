
/*
██████╗░███████╗░██████╗██╗░░██╗████████╗░█████╗░██████╗░
██╔══██╗██╔════╝██╔════╝██║░██╔╝╚══██╔══╝██╔══██╗██╔══██╗
██║░░██║█████╗░░╚█████╗░█████═╝░░░░██║░░░██║░░██║██████╔╝
██║░░██║██╔══╝░░░╚═══██╗██╔═██╗░░░░██║░░░██║░░██║██╔═══╝░
██████╔╝███████╗██████╔╝██║░╚██╗░░░██║░░░╚█████╔╝██║░░░░░
╚═════╝░╚══════╝╚═════╝░╚═╝░░╚═╝░░░╚═╝░░░░╚════╝░╚═╝░░░░░
*/
@media screen and (min-width: 1024px) {
  .Hero {
    padding: 0px 30px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    height: 820px;
  }
  
  .hero-inner{
    width: 100%;
    height: 100%;
    padding: 200px 30px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .hero-title {
    font-weight: 900;
    font-size: 5em;
    line-height: 90px;
    color: white;
    text-align: left;
    margin: 0;
  }

  .hero-sub-title {
    font-size: 3em;
    line-height: 90px;
    color: white;
    text-align: left;
    margin: 0;
  }
  
  .hero-button {
    float: left;
    background-color:#c60204;
    color: white;
    font-size: 1.56em;
    padding: 12px 30px;
    border-radius: 6px;
    margin-top: 16px;
  }
}

/*
████████╗░█████╗░██████╗░██╗░░░░░███████╗████████╗
╚══██╔══╝██╔══██╗██╔══██╗██║░░░░░██╔════╝╚══██╔══╝
░░░██║░░░███████║██████╦╝██║░░░░░█████╗░░░░░██║░░░
░░░██║░░░██╔══██║██╔══██╗██║░░░░░██╔══╝░░░░░██║░░░
░░░██║░░░██║░░██║██████╦╝███████╗███████╗░░░██║░░░
░░░╚═╝░░░╚═╝░░╚═╝╚═════╝░╚══════╝╚══════╝░░░╚═╝░░░
*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Hero {
    padding: 0px 30px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    height: 820px;
  }
  
  .hero-inner{
    width: 100%;
    height: 100%;
    padding: 200px 30px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .hero-title {
    font-weight: 900;
    font-size: 5em;
    line-height: 90px;
    color: white;
    text-align: left;
    margin: 0;
  }

  .hero-sub-title {
    font-size: 3em;
    line-height: 90px;
    color: white;
    text-align: left;
    margin: 0;
  }
  
  .hero-button {
    float: left;
    background-color:#c60204;
    color: white;
    font-size: 1.56em;
    padding: 12px 30px;
    border-radius: 6px;
    margin-top: 16px;
  }
}

/*
██████╗░██╗░░██╗░█████╗░███╗░░██╗███████╗
██╔══██╗██║░░██║██╔══██╗████╗░██║██╔════╝
██████╔╝███████║██║░░██║██╔██╗██║█████╗░░
██╔═══╝░██╔══██║██║░░██║██║╚████║██╔══╝░░
██║░░░░░██║░░██║╚█████╔╝██║░╚███║███████╗
╚═╝░░░░░╚═╝░░╚═╝░╚════╝░╚═╝░░╚══╝╚══════╝
*/
@media screen and (max-width: 767px) {
  .Hero {
    padding: 0px 16px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    height: 640px;
  }
  
  .hero-inner{
    width: 100%;
    height: 100%;
    padding: 100px 16px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .hero-title {
    font-weight: 900;
    font-size: 3em;
    color: white;
    text-align: left;
    margin: 0;
  }

  .hero-sub-title {
    font-size: 1.64em;
    color: white;
    text-align: left;
    margin: 0;
  }
  
  .hero-button {
    float: left;
    background-color:#c60204;
    color: white;
    font-size: 1.24em;
    padding: 12px 30px;
    border-radius: 6px;
    margin-top: 16px;
  }
}

