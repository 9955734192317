@media screen and (min-width: 1024px) {
  .TripleRow {
    width: 100%;
    max-width: 1024px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 36px;
    hyphens: auto; 
  }

  .multiple-row-element {
    padding: 10px;
    box-sizing: border-box;
  }

  .multiple-row-element-inner {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .multiple-row-element-inner-image {
    width: 38%;
    max-width: 100px;
    margin: 0 auto;
  }

  .multiple-row-element-inner-headline {
    margin: 24px 0 0 0;
    font-size: 1.56em;
    font-weight: bold;
    width: 100%;
  }

  .multiple-row-element-inner-description {
    margin: 6px 0 0 0;
    font-size: 1.12em;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .TripleRow {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    hyphens: auto; 
  }

  .multiple-row-element {
    padding: 10px;
    box-sizing: border-box;
  }

  .multiple-row-element-inner {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .multiple-row-element-inner-image {
    width: 38%;
    max-width: 72px;
    margin: 0 auto;
  }

  .multiple-row-element-inner-headline {
    margin: 16px 0 0 0;
    font-size: 1.36em;
    font-weight: bold;
    width: 100%;
  }

  .multiple-row-element-inner-description {
    margin: 0 0 0 0;
    font-size: 1em;
  }
}

@media screen and (max-width: 767px) {
  .TripleRow {
    width: 100%;
    margin-top: 36px;
  }

  .multiple-row-element {
    width: 100% !important;
    padding: 10px;
    box-sizing: border-box;
  }

  .multiple-row-element-inner {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .multiple-row-element-inner-image {
    width: 50%;
    max-width: 72px;
    margin: 0 auto;
  }

  .multiple-row-element-inner-headline {
    margin: 16px 0 0 0;
    font-size: 1.56em;
    font-weight: bold;
    width: 100%;
  }

  .multiple-row-element-inner-description {
    margin: 0 0 0 0;
    font-size: 1em;
  }
}
