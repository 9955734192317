.Home {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.centered {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}





/*
    ░██████╗███████╗░█████╗░░█████╗░███╗░░██╗██████╗░
    ██╔════╝██╔════╝██╔══██╗██╔══██╗████╗░██║██╔══██╗
    ╚█████╗░█████╗░░██║░░╚═╝██║░░██║██╔██╗██║██║░░██║
    ░╚═══██╗██╔══╝░░██║░░██╗██║░░██║██║╚████║██║░░██║
    ██████╔╝███████╗╚█████╔╝╚█████╔╝██║░╚███║██████╔╝
    ╚═════╝░╚══════╝░╚════╝░░╚════╝░╚═╝░░╚══╝╚═════╝░
*/

.home-second {
  width: 100%;
  height: auto;
  /*background-color:rgb(206, 0, 2, 0.11);*/
  padding: 72px 30px;
  box-sizing: border-box;
}

.home-left {
  float: left;
  width: 50%;
}

.left-right-padding {
  padding: 40px;
  box-sizing: border-box;
}

.home-right {
  float: left;
  width: 50%;
}

.home-big-headline {
  margin: 30px 0 0 0;
  font-size: 3em;
  text-align: left;
  font-weight: 900;
  color: white;
}

.home-sub-headline {
  margin: 0 0 0 0;
  font-size: 1.2em;
  text-align: left;
  color: white;
}

.home-standard-paragraph {
  text-align: left;
  color: white;
  line-height: 26px;
}

.home-image {
  width: 100%;
}

.home-btn {
  padding: 12px 30px;
  border-radius: 10px;
  color: white;
  font-size: 1.12em;
  background-color: red;
  float: left;
}

.home-third {
  width: 100%;
  height: auto;
  padding: 72px 30px;
  box-sizing: border-box;
}

.home-fourth {
  width: 100%;
  height: auto;
  background-color: #c60204;
  padding: 72px 30px;
  box-sizing: border-box;
}

.home-fifth {
  width: 100%;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
}

.home-square {
  float: left;
  width: 33.3%;
  height: 400px;
  padding: 30px;
  box-sizing: border-box;
}

.home-square-image {
  width: 100%;
  height: 300px;
  background-color: #ccc;
}

.home-square-paragraph-head {
  text-align: left;
  font-size: 2em;
  margin: 10px 0 0 0;
}

.home-square-paragraph-small {
  text-align: left;
  font-size: 1em;
  margin: 0 0 0 0;
}
