@media screen and (min-width: 1024px) {
    .ContactBox {
        width: 100%;
        max-width: 600px;
        height: 300px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #c60204;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: -36px;
        position: relative;
        z-index: 999;
    }

    .contactbox-image-box{
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .contact-box-inline-img{
        height: 16px;
        margin-right: 6px;
        margin-top: 8px;
    }

    .contactbox-text-box{
        float: left;
        width: 100%;
        height: 100%;
        padding: 36px 20px 20px 54%;
        box-sizing: border-box;
        text-align: left;
    }

    .contactbox-headline{
        font-size: 2.4em;
        font-weight: bold;
        color: white;
        margin: 24px 0 0 0;
    }

    .contactbox-text{
        margin-top: 16px;
        font-size: 1.12em;
        line-height: 1.4;
        color: white;
    }
  }
  
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .ContactBox {
        width: 100%;
        max-width: 600px;
        height: 300px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #c60204;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: -24px;
        position: relative;
        z-index: 999;
    }

    .contactbox-image-box{
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .contact-box-inline-img{
        height: 16px;
        margin-right: 6px;
        margin-top: 8px;
    }

    .contactbox-text-box{
        float: left;
        width: 100%;
        height: 100%;
        padding: 36px 20px 20px 54%;
        box-sizing: border-box;
        text-align: left;
    }

    .contactbox-headline{
        font-size: 2.4em;
        font-weight: bold;
        color: white;
        margin: 24px 0 0 0;
    }

    .contactbox-text{
        margin-top: 16px;
        font-size: 1.12em;
        line-height: 1.4;
        color: white;
    }
  }
  
  
  @media screen and (max-width: 767px) {
    .ContactBox {
        height: 200px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #c60204;
        margin: 0 16px -24px 16px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        z-index: 999;
    }

    .contactbox-image-box{
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .contact-box-inline-img{
        float: left;
        width: 26%;
        margin: 36px 12%;
    }

    .contact-box-inline-text{
        display: none;
    }

    .contactbox-text-box{
        float: left;
        width: 100%;
        height: 100%;
        padding: 20px 20px 20px 54%;
        box-sizing: border-box;
        text-align: left;
    }

    .contactbox-headline{
        float: left;
        font-size: 1.4em;
        font-weight: bold;
        color: white;
        margin: 0;
    }

    .contactbox-text{
        width: 100%;
        margin-top: 16px;
        font-size: 1em;
        color: white;
    }
  }